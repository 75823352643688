import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export const resetSuccessState = (props) => {
  if (props && props.saveSuccess) {
    props && props.resetProfileSuccess();
  }
};

export const getDurationUnit = (data) => {

  return (
    (_get(data, 'scopeAndBudget.durationUnit', '-') === "PERHOUR" ? "Hours" : _get(data, 'scopeAndBudget.durationUnit', '-') === "PERDAY" ? "Days" : _get(data, 'scopeAndBudget.durationUnit', '-') === "PERWEEK" ? "Weeks" : _get(data, 'scopeAndBudget.durationUnit', '-') === "PERMONTH" ? "Months" : "Hours") ? (_get(data, 'scopeAndBudget.durationUnit', '-') === "PERHOUR" ? "Hours" : _get(data, 'scopeAndBudget.durationUnit', '-') === "PERDAY" ? "Days" : _get(data, 'scopeAndBudget.durationUnit', '-') === "PERWEEK" ? "Weeks" : _get(data, 'scopeAndBudget.durationUnit', '-') === "PERMONTH" ? "Months" : "Hours") : ""
  )
}

export const getDurationLabel = (unit) => {

  return (
    (unit === "PERHOUR" ? "Hours" : unit === "PERDAY" ? "Days" : unit === "PERWEEK" ? "Weeks" : unit === "PERMONTH" ? "Months" : "Hours")
  )
}

export const getContractDurationLabel = (unit) => {

  return (
    (unit === "MONTH" ? "Months" : unit === "DAYS" ? "Days" : unit === "PERMANENT" ? "Permanent" : "Months")
  )
}

export const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

export const getSelectedSpecSkills = (expertiseSkills, selectedSpecilization) => {
  const list = [];
  _map(expertiseSkills, (expertiseSkill) => {
    if (_get(expertiseSkill, selectedSpecilization)) {
      list.push(..._get(expertiseSkill, selectedSpecilization, []));
    }
  });
  return list;
};

export const isAddSkillEligible = (expertiseSkills, selectedSpecilization, selectedSkills) => {
  const allSkills = getSelectedSpecSkills(expertiseSkills, selectedSpecilization);
  return allSkills.length !== selectedSkills.length;
};

// export const getGigLocation = (data, isRemote) => {
//   if (isRemote === 'true') {
//     return 'Remote';
//   } else {
//     if (_get(data, 'location.country')) {
//       let state = _get(data, 'location.state', '')
//         ? _get(data, 'location.state', '')
//         : _get(data, 'location.province', '');
//       return `${_get(data, 'location.city', '')} ${_get(data, 'location.city', '') === '' ? '' : ','
//         } ${state} ${_get(data, 'location.state', '') === '' ? '' : ','} ${_get(
//           data,
//           'location.country',
//           ''
//         )}`;
//     } else {
//       return 'Remote';
//     }
//   }
// };

export const getGigLocation = (data, isRemote) => {
  if (isRemote === 'true') {
    return 'Remote';
  } else {
    if (_get(data, 'location.country')) {
      return `${_get(data, 'location.city', '')} ${_get(data, 'location.city', '') === '' ? '' : ''
        }, ${_get(data, 'location.country', '')}`;
    } else {
      return 'Remote';
    }
  }
};

export const getGigLocationDis = (data, isRemote) => {
  if (isRemote === 'true') {
    return 'Not Disclosed';
  } else {
    if (_get(data, 'location.country')) {
      let state = _get(data, 'location.state', '')
        ? _get(data, 'location.state', '')
        : _get(data, 'location.province', '');
      return `${_get(data, 'location.city', '')} ${_get(data, 'location.city', '') === '' ? '' : ','
        } ${state} ${_get(data, 'location.state', '') === '' ? '' : ','} ${_get(
          data,
          'location.country',
          ''
        )}`;
    } else {
      return 'Not Disclosed';
    }
  }
};

export const getHireLocation = (data) => {
  if (data === '') {
    return 'Remote';
  } else {
    if (_get(data, 'country')) {
      return `${_get(data, 'country', '')}`;
    } else {
      return 'Remote';
    }
  }
};

export const getStringLocation = (data, isRemote) => {
  if (isRemote === 'true') {
    return 'Remote';
  } else {
    if (typeof _get(data, 'location') !== 'string' && _get(data, 'location')) {
      return `${_get(data, 'location.city', '')}, ${_get(data, 'location.state', '')}, ${_get(
        data,
        'location.country',
        ''
      )}`;
    } else {
      return 'Remote';
    }
  }
};

export const isAddSkillEligibleEngineer = (selectedSkills) => {
  return selectedSkills.length !== 10;
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date) => {
  let dateValue = new Date(date);
  return [
    padTo2Digits(dateValue.getDate()),
    padTo2Digits(dateValue.getMonth() + 1),
    dateValue.getFullYear()
  ].join('/');


};
export const formatDateWithTime = (date) => {
  let dateValue = new Date(date);
  const formattedDate = [
    padTo2Digits(dateValue.getDate()),
    padTo2Digits(dateValue.getMonth() + 1),
    dateValue.getFullYear()
  ].join('/');

  const hours24 = dateValue.getHours();
  const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
  const ampm = hours24 >= 12 ? 'PM' : 'AM';
  const formattedTime = [
    padTo2Digits(hours12),
    padTo2Digits(dateValue.getMinutes()),
    padTo2Digits(dateValue.getSeconds())
  ].join(':');

  return `${formattedDate} ${formattedTime} ${ampm}`;
};
export const scrollOnTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

export const disableEnableBodyScroll = () => {
  scrollOnTop();
  if (document.body.style.overflowY != "hidden") {
    document.body.style.overflowY = "hidden"
  }
  else {
    document.body.style.overflowY = "auto"
  }
}

export const downloadFileFromLink = (url) => {
  const link = document.createElement('a')
  link.href = url
  link.target = "_blank"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getJobType = (data) => {

  return (
    (_get(data, 'titleAndSkills.jobType') === "proposal" ? "Proposal" : _get(data, 'titleAndSkills.jobType') === "project" ? "Project" : _get(data, 'titleAndSkills.jobType') === "fixedTermContract" ? "Fixed Term Project" : "Not Disclose")
  )
}

export const NumberFormatter = (amount) => {
  const amountVal = Math.round(Number(amount) * 100) / 100;

  const parts = amountVal.toString().split(".");
  let num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (parts[1] && parts[1].length > 1) {
    num = num + "." + parts[1];
  } else if (parts[1] && parts[1].length === 1) {
    num = num + "." + parts[1] + "0";
  } else {
    num = num + ".00";
  }

  return num;
};

export const checkIfVerified = (referralArr) => {
  let result = [];
  if (
    Array.isArray(referralArr) && referralArr.length >= 1
  ) {
    referralArr.map((item, index) => {
      if (item.isPublished) {
        result.push(item)
      }
    })
  }
  return result
}
