import React from 'react';
import { Typography, Box, Stack, Button } from '@mui/material';

function TruzoTransactionFailed() {

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}>

      <Box
        sx={{
          width: "50%",
          bgcolor: 'background.paper',
          p: 4,
        }}>
        <Box
          sx={{
            textAlign: "center",
            img: {
              width: "250px",
              height: "100%",
              objectFit: "contain"
            }
          }}>
          <img src='/images/truzo-failed.png' alt="truzo-failed" />
        </Box>
        <Box
          sx={{ my: 2 }}>
          <Typography
            id="modal-title"
            sx={{
              fontSize: "24px", // Fixed typo from 'fontsize' to 'fontSize'
              fontWeight: 600,
              lineHeight: "28.8px",
              textAlign: "center",
              color: "#1E1E1E"
            }}>
            Your Truzo Account Awaits Completion - Finalize Now!
          </Typography>
          <Typography
            id="modal-description"
            sx={{
              mt: 3,
              fontSize: "16px", // Fixed typo from 'fontsize' to 'fontSize'
              fontWeight: 400,
              lineHeight: "22.4px",
              textAlign: "center",
              color: "#757575"
            }}>
            Oops! It seems your account setup on Truzo was not completed.
            For seamless payment processing, please complete your registration on Truzo.
          </Typography>
        </Box>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          sx={{
            mt: 3,
            "& button": {
              width: "300px",
              textTransform: "none"
            }
          }}>
          <Button
            sx={{
              color: "#FF8008",
              textDecoration: "underline"
            }}>Return to Dashboard</Button>
          <Button variant='contained' sx={{ background: "#0140AA" }}>Try again</Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default TruzoTransactionFailed;

// import { Modal, Typography, Box, Stack, Button } from '@mui/material';
// import React, { useState } from 'react';

// function TruzoTransactionFailed() {
//   // State to control modal visibility
//   const [open, setOpen] = useState(true);

//   // Handlers for modal
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="modal-title"
//       aria-describedby="modal-description"
//     >
//       <Box sx={{
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: "40%",
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//         borderRadius: '8px',
//       }}>
//         <Box
//           sx={{
//             textAlign: "center",
//             img: {
//               width: "250px",
//               height: "100%",
//               objectFit: "contain"
//             }
//           }}>
//           <img src='/images/truzo-failed.png' alt="truzo-failed" />
//         </Box>
//         <Box
//           sx={{ my: 2 }}>
//           <Typography
//             id="modal-title"
//             sx={{
//               fontSize: "24px", // Fixed typo from 'fontsize' to 'fontSize'
//               fontWeight: 600,
//               lineHeight: "28.8px",
//               textAlign: "center",
//               color: "#1E1E1E"
//             }}>
//             Your Truzo Account Awaits Completion - Finalize Now!
//           </Typography>
//           <Typography
//             id="modal-description"
//             sx={{
//               mt: 2,
//               fontSize: "16px", // Fixed typo from 'fontsize' to 'fontSize'
//               fontWeight: 400,
//               lineHeight: "22.4px",
//               textAlign: "center",
//               color: "#757575"
//             }}>
//             Oops! It seems your account setup on Truzo was not completed.
//             For seamless payment processing, please complete your registration on Truzo.
//           </Typography>
//         </Box>
//         <Stack
//           flexDirection={"row"}
//           alignItems={"center"}
//           justifyContent={"center"}
//           sx={{
//             mt: 2,
//             "& button": {
//               width: "300px",
//               textTransform: "none"
//             }
//           }}>
//           <Button
//             sx={{
//               color: "#FF8008",
//               textDecoration: "underline"
//             }}>Return to Dashboard</Button>
//           <Button variant='contained' sx={{ background: "#0140AA" }}>Try again</Button>
//         </Stack>
//       </Box>
//     </Modal>
//   );
// }

// export default TruzoTransactionFailed;
