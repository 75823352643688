import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';

const rows = [
  { date: '2024-01-15', debit: "", credit: "+1000", balance: "10,500", status: "Success", remark: "No Remark" },
  { date: '2024-01-15', debit: "-500", credit: "", balance: "9,500", status: "Success", remark: "Payment released" },
  { date: '2024-01-15', debit: "", credit: "+1000", balance: "9,000", status: "Failed", remark: "No Remark" },
  { date: '2024-01-15', debit: "", credit: "+1000", balance: "9,000", status: "Failed", remark: "No Remark" },
];

// Table component
const TransactionTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, mt: 4 }}>
        <TableHead sx={{ background: "#F5F6FA", "& td, & th": { border: 0 }, borderRadius: "5px" }}>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Debit</TableCell>
            <TableCell>Credit</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Remark</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <TableRow
                key={row.id}
                sx={{ '& td, & th': { border: 0 } }}
              >
                <TableCell>{row.date}</TableCell>
                <TableCell sx={{ color: row.debit && "#FF3B30" }}>{row.debit ? row.debit : "-"}</TableCell>
                <TableCell sx={{ color: row.credit && "#34C759" }}>{row.credit ? row.credit : "-"}</TableCell>
                <TableCell>{row.balance}</TableCell>
                <TableCell>
                  <span
                    style={{
                      padding: '6px 12px',
                      borderRadius: '16px',
                      backgroundColor: row.status === 'Success' ? '#34C7591A' : '#FF3B301A',
                      color: row.status === 'Success' ? '#34C759' : '#FF3B30',
                    }}
                  >
                    {row.status}
                  </span>
                </TableCell>
                <TableCell>{row.remark}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </TableContainer>
  );
}

export default TransactionTable
