import * as React from "react"
const RightArrow
 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#575757"
      d="m20.083 12-1.9-1.867 2.8-2.8H.75V4.667h20.233l-2.766-2.8L20.117 0l5.966 6-6 6Z"
    />
  </svg>
)
export default RightArrow
