import { Button, Card, Chip, Grid, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

function TruzoWallet() {
  const info = {
    fullName: "Lorem Ipsum",
    email: "lorem@gmail.com",
    phoneNumber: "+91 1234567890",
    verified: false,
  }
  const theme = useTheme();

  return (
    <Card
      sx={{
        mt: 2,
        px: 2,
        py: 2,
        position: "relative",
        "& img": {
          width: "100%",
          height: "137px",
          objectFit: "contain",
        }
      }}>
      <Grid container spacing={1}>
        <Grid item sm={12} md={12} lg={8}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column"
              }
            }}>
            <Box>
              <img src={"/images/truzo-logo.png"} alt='truzo' />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: "30px",
                  lineHeight: "30px",
                  fontWeight: 600,
                  color: "#27A0B4",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "20px",
                    lineHeight: "20px",
                  }
                }}>
                {
                  info.verified ?
                    "Your Truzo Account Has Been Linked" :
                    "Truzo is Not Linked Yet"
                }

              </Typography>
              {
                info.verified ?
                  <Box
                    sx={{
                      "& .label": {
                        fontSize: "14px",
                        lineHeight: "30px",
                        color: "#575757",
                        py: "0px !important",
                        px: "10px",
                        width: "130px"
                      },
                      "& .value": {
                        fontSize: "14px",
                        lineHeight: "30px",
                        fontWeight: 600,
                        color: "#575757",
                        py: "0px !important",
                        px: "10px"
                      }
                    }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography className="label">Full Name:</Typography>
                      <Typography className="value">{info?.fullName}</Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography className="label">Email:</Typography>
                      <Typography className="value">{info.email}</Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography className="label">Phone Number:</Typography>
                      <Typography className="value">{info.phoneNumber}</Typography>
                    </Box>
                  </Box>
                  :
                  <Typography
                    sx={{
                      fontSize: "18px",
                      lineHeight: "30px",
                      fontWeight: 400,
                      color: "#575757",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "16px",
                        lineHeight: "20px",
                      }
                    }}>To enable seamless transactions for your gig activities, please link your Truzo account.</Typography>
              }
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} md={4} lg={4} textAlign={"end"} alignSelf={"center"}>
          <Box>
            {info.verified &&
              <Box
                sx={{ position: "absolute", top: "10px", right: "20px", }}>
                <Chip label="VERIFIED" size="medium" sx={{ background: "#34C759", color: "#ffffff", fontWeight: 500 }} />
              </Box>
            }
            <Button
              size='large'
              sx={{
                width: "300px",
                backgroundImage: "linear-gradient(#ff8008, #ff8008, #ffc837)",
                color: "#ffffff",
                borderRadius: "5px",
              }}>
              Create your truzo account
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card >
  )
}

export default TruzoWallet
