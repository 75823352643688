require('dotenv').config();
let COUNTRY_URL = 'https://countriesnow.space/api/v0.1/countries';

const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  API_URL: process.env.REACT_APP_API_URL,
  COUNTRY_URL,
  TRUZO_ACCOUNT: process.env.REACT_APP_TRUZO_ACCOUNT
};

export default config;
