import * as React from "react"
const ExportIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#595757"
      d="M10 10h3l-4 4-4-4h3V6h2v4Zm2-8H2v16h14V6h-4V2ZM0 .992C0 .444.447 0 .999 0H13l5 5v13.992A1 1 0 0 1 17.007 20H.993A1 1 0 0 1 0 19.008V.992Z"
    />
  </svg>
)
export default ExportIcon
