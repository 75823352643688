// import * as yup from 'yup';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import * as validationSchemas from './formikValidations';

const isObjectInavlidSchema = async (schema, dataObj) => {
  const retVal = await validationSchemas[schema].validate(dataObj).catch(function (err) {
    return true;
  });
  return retVal;
};

export const identifyNewGigStep = async (dataObj) => {
  const steps = [
    {
      schemaName: 'newGigProjectDetailsSchema',
      objectKey: 'titleAndSkills'
    },
    {
      schemaName: (dataObj?.titleAndSkills?.jobType=="recruitment")?'newRecruitmentSchema':'newGigProjectDurationSchema',
      objectKey: 'scopeAndBudget'
    },
    {
      schemaName: 'newGigProjectDeliverablesSchema',
      objectKey: 'deliverablesAndLocation'
    }
  ];

  let selectedStep = 3;

  for (var i = 0; i < steps.length; i++) {
    const val = await isObjectInavlidSchema(steps[i].schemaName, dataObj[steps[i].objectKey]);
    if (val === true) {
      selectedStep = i + 1;
      break;
    }
  }
  return selectedStep;
};
