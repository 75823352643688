import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Autocomplete } from '@mui/material';
import { Formik, Form } from 'formik';
import { newGigProjectDetailsSchema } from '../../../../utils/formikValidations';
import FormTextField from '../../../../components/util/FormTextField';
import { setNewGigStep, setNewGigSuccess, setNewGigError } from '../../../../store/actions/newGig';
import { saveNewDraftGigApi } from '../../../../services/gig';
import { getExpertiseSkillsDetApi, getSkillsList, getToolslist } from '../../../../services/expertisewithskills';
import Chip from '@mui/material/Chip';
import './Step3.scss';
import { useHistory } from 'react-router-dom';
import StepPopup from './StepPopup';
import { styled } from '@mui/material/styles';
import Vector from '../../../../assests/Vector.png';
import { DraftStep1Btn, PaddedBox } from '../../../app.styles';
import { Box } from '@mui/system';
import { scrollOnTop } from '../../../../utils/helpers';
import RichTextEditor from 'react-rte';
import './Step_1.css';
const Step = (props) => {

  const otherskills = [];
  const his = useHistory();
  const titleAndSkills = _get(props, 'newGig.titleAndSkills', props.defaultObject.titleAndSkills);
  const fieldOfSpecialization = _get(props, 'newGig.fieldOfSpecialization', '');
  const [otherskill, setOtherSkill] = useState(props.newGig?.otherskills || []);
  const [valueExpertise, setValueExpertise] = useState([]);
  const [expertiseList, setExpertiseList] = useState([]);
  const [openDashboard, setOpenDashboard] = useState(false);
  const percentage = _get(props, 'profile.profileCompletionPercent', 0);
  const [errorFieldOfSpecialization, setErrorFieldOfSpecialization] = useState(false);
  const [tools, setToolsList] = useState([]);

  const getTools = async () => {
    let tool = await getToolslist();
    if (tool?.data && tool?.data.length > 0) {
      setToolsList(tool.data);
    }
  };

  useEffect(() => {
    scrollOnTop();
    getTools();
  }, [props]);

  const modalOpen = () => {
    setOpenDashboard(true);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300
      }
    }
  };

  const getExpertiseList = async () => {
    let expertise = await getExpertiseSkillsDetApi();
    if (expertise?.data && expertise?.data.length > 0) {
      if (fieldOfSpecialization && fieldOfSpecialization.length > 0) {
        const listToDeleteSet = new Set(fieldOfSpecialization);
        let expertiseSelectedRemove = expertise.data.filter(e => {
          return !listToDeleteSet.has(e.areaofexpertise);
        });
        setExpertiseList(expertiseSelectedRemove);
      } else {
        setExpertiseList(expertise.data);
      }
    }
  };

  const [skillsList, setSkillList] = useState([]);

  const getSkills = async () => {
    let skills = await getSkillsList();
    if (skills?.data && skills?.data.length > 0) {
      setSkillList(skills.data);
    }
  };
  const titleAndSkillsforskills = _get(props, 'newGig.titleAndSkills');
  const [valueskill, setvalueskill] = useState(titleAndSkillsforskills?.skillsRequired ? titleAndSkillsforskills?.skillsRequired.map(x => x.engineerSkill) : []);
  const [skillError, setSkillError] = useState(false);

  useEffect(() => {
    if (percentage < 60) {
      modalOpen();
    }
  }, [props]);

  useEffect(() => {
    props.changeHeadTitle("Let’s start with the basics.");
    getExpertiseList();
    getSkills();
  }, [props]);


  const saveToDraft = ({ values, validateForm, setTouched, setNewGigError, setNewGigSuccess }) => {
    validateForm().then((errors) => {


      if (_isEmpty(errors)) {
        if (!values.fieldOfSpecialization && values.fieldOfSpecialization.length == 0) {
          setErrorFieldOfSpecialization(true);
          return
        }
        let arr = []
        let co = {}
        for (let i = 0; i < valueskill.length; i++) {
          co = { engineerSkill: valueskill[i], skillProficiency: 'entry' }
          arr.push(co)
        };
        let finalData = { ...values, 'skillsRequired': arr };
        // verify if errors object is equals to '{}' an empty object
        const obj = {
          titleAndSkills: {
            ...finalData
          },
          fieldOfSpecialization: values.fieldOfSpecialization,
          skillsRequired: arr,
          status: 'DRAFT'
        };
        saveNewDraftGigApi(obj, setNewGigError, setNewGigSuccess).then((e)=>{
          if(e.status === 200){
            if(e?.data?.gig?._id){
              his.push(`/gig/edit/${e?.data?.gig?._id}`);
            } else {
              his.push('/gig/active');
            }
          }
          else{
            // toast.error(e.message);
          }
        }).catch((err)=>{
          console.error(err)
        });
        // toast.success('Draft Saved Sucessfully');
      } else {
        return setTouched(errors);
      }
    });
  };

  const toolBarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" }
    ]
  };

  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString(props.newGig?.titleAndSkills?.projectDetails || "", "html")
  );

  const [hasChanged, sethasChanged] = useState(false)

  /**
   * Handle editor's change.
   * @param {import('react-rte').EditorValue} value Value from the editor.
   */
  function onChange(value) {
    // console.log(value.toString("html"));
    setEditorValue(value);
    setFieldValue('projectDetails', value.toString("html"))
  }

  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "0px" : "0px"}`, marginTop: '-5px' }} >

        <div className='main-heading'>
          <div className='heading-body'>1/3 </div>
          Title & Skills
        </div>

        <div className='step1-body'>
          <div className='img-container'>
            <div className='basic-text'>Let’s start with the basics</div>
            <div className='text-contaner'>You can save changes at each step and continue from where you left off.</div>
            <div className='vector-img-step1'>
              <img className='step-1-img' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Post+a+Gig+Step+1.svg" alt="profile" />
            </div>
          </div>

          <div className='Formrik-Container'>
            <Formik
              initialValues={{
                ...titleAndSkills,
                fieldOfSpecialization
              }}
              enableReinitialize={true}
              validationSchema={newGigProjectDetailsSchema}
              onSubmit={(values) => {
                if (valueskill && valueskill.length === 0) {
                  setSkillError(true);
                  return
                }
                if (!values.fieldOfSpecialization && values.fieldOfSpecialization.length == 0) {
                  setErrorFieldOfSpecialization(true);
                  return
                }
                let arr = []
                let co = {}
                for (let i = 0; i < valueskill.length; i++) {
                  co = { engineerSkill: valueskill[i], skillProficiency: 'entry' }
                  arr.push(co)
                };
                let finalData = { ...values, 'skillsRequired': arr };
                const obj = {
                  titleAndSkills: {
                    ...finalData
                  },
                  fieldOfSpecialization: values.fieldOfSpecialization
                };
                props.setNewGigStep(obj);
              }}
            >
              {(formProps) => {
                const { values, setFieldValue, touched, errors } = formProps;
                return (

                  <Form noValidate autoComplete="off">

                    <div className='all-item-step2'>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl
                          fullWidth
                          variant="outlined"
                          required="required"
                        >
                          <InputLabel id="demo-simple-select-label">Contract Type</InputLabel>
                          <Select
                            value={_get(values, 'jobType')}
                            label="Contract Type"
                            onChange={(event) => {
                              setFieldValue('jobType', event.target.value);
                            }}
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem value="recruitment" sx={{ alignItems: "center", display: "flex" }}>Recruitment</MenuItem>
                            <MenuItem value="gig" sx={{ alignItems: "center", display: "flex" }}>Gig</MenuItem>
                            <MenuItem value="fractional" sx={{ alignItems: "center", display: "flex" }}>Fractional</MenuItem>
                            {/* <MenuItem value="proposal" sx={{ alignItems: "center", display: "flex" }}>Proposal</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>

                    <div className='project-title-data'>

                      <FormTextField
                        fullWidth
                        name="projectTitle"
                        label="Project title"
                        placeholder="e.g. Civil design calculations"
                        value={_get(values, 'projectTitle')}
                        {...formProps}
                        required="required"
                      />

                    </div>

                    <div className='text-feild-step-1'>
                      <label style={{color: '#7E84A3', fontSize: '12.5px'}}>Project Details<sup style={{color: 'red'}}>*</sup></label>
                      <RichTextEditor
                        className="new-post-editor-step-1"
                        onChange={(value) => {
                          setEditorValue(value);
                          setFieldValue('projectDetails', value.toString("html"))
                          sethasChanged(true)
                        }}
                        value={editorValue}
                        toolbarConfig={toolBarConfig}
                        placeholder='Project details'
                      />
                      {
                        ((!!editorValue && !editorValue.getEditorState().getCurrentContent().hasText() && hasChanged) || (Boolean(_get(errors, 'projectDetails'))))
                        &&
                        <p className='error-msg-step1-texteditor'>Project details is required</p>
                      }
                    </div>

                    <div className='field-engineer-data'>
                      <Autocomplete
                        fullWidth
                        multiple
                        id="tags-standard"
                        value={values.fieldOfSpecialization || []}
                        options={expertiseList.map((option) => option.areaofexpertise)}
                        getOptionDisabled={(options) => (values.fieldOfSpecialization.length > 2 ? true : false)}
                        onChange={(event, newValue) => {
                          setErrorFieldOfSpecialization(false);
                          let fielsOfEng = _get(values, 'fieldOfSpecialization');
                          const cloneExpertiseList = [...expertiseList];
                          if (newValue.length > fielsOfEng.length) {
                            const expertiseAfterRemoving = cloneExpertiseList.filter(e => e.areaofexpertise != newValue[newValue.length - 1]);
                            setExpertiseList(expertiseAfterRemoving);
                          } else {
                            const difference = fielsOfEng.filter(e => newValue.indexOf(e) === -1);
                            cloneExpertiseList.push({ 'areaofexpertise': difference[0] });
                            const uniqueExpertise = [...new Map(cloneExpertiseList.map(item => [item['areaofexpertise'], item])).values()];
                            setExpertiseList(uniqueExpertise);
                          }

                          let finalArray = [];
                          for (let item of newValue) {
                            if (typeof item === 'object') {
                              finalArray.push(item.areaofexpertise);
                            } else {
                              finalArray.push(item);
                            }
                          }
                          setFieldValue('fieldOfSpecialization', finalArray);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Field of engineering"
                            required
                            error={_get(touched, 'fieldOfSpecialization') && Boolean(_get(errors, 'fieldOfSpecialization'))}
                            helperText={touched.fieldOfSpecialization && errors.fieldOfSpecialization}
                          />
                        )}
                        validationerror={
                          touched.fieldOfSpecialization && Boolean(errors.fieldOfSpecialization)
                        }
                      />
                    </div>


                    <div className='skill-step1-data'>
                      <Autocomplete
                        multiple
                        fullWidth
                        options={skillsList.map((option) => option.skills)}
                        freeSolo
                        value={valueskill}
                        onChange={(event, newValue) => {
                          setvalueskill(newValue);
                          if (newValue && newValue.length > 0) {
                            setSkillError(false);
                          } else {
                            setSkillError(true);
                          }
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Skills"
                            placeholder="Engineering skills required (e.g. Simulation)"
                            required
                            error={skillError}
                            helperText={skillError ? "Skills is required" : ''}
                          />
                        )}
                      />
                    </div>

                    <div className='tools-technology-data'>
                      <Autocomplete
                        multiple
                        options={tools.map((option) => option.tools)}
                        freeSolo
                        value={values.tools}
                        onChange={(event, newValue) => {
                          setFieldValue('tools', newValue);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Tools & Technologies"
                          />
                        )}
                      />

                    </div>

                    <div className='step1-btn-container' >

                      <DraftStep1Btn

                        onClick={() => {
                          if (percentage < 60) {
                            modalOpen();
                          } else {
                            saveToDraft({ ...formProps, ...props });
                          }
                        }}
                      >
                        Save as Draft
                      </DraftStep1Btn>

                      <div>
                        {(percentage < 60) ?
                          <Button
                            variant="contained"
                            className="primary-Btn"
                            sx={{ marginLeft: '20px', width: "120px", height: "45px" }}
                            onClick={modalOpen}
                          >
                            NEXT
                          </Button> :
                          <Button
                            variant="contained"
                            className="primary-Btn"
                            type="submit"
                            sx={{ marginLeft: '20px', width: "120px", height: "45px" }}
                          >
                            NEXT
                          </Button>
                        }
                      </div>
                    </div>



                  </Form>

                );
              }}
            </Formik>
            {openDashboard ? <StepPopup modalOpen={openDashboard} modalClose={(e) => { setOpenDashboard(false) }} /> : <></>}
          </div>
        </div>
      </PaddedBox>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    newGig: state.newGig,
    error: state.newGig.error,
    saveSuccess: state.newGig.saveSuccess,
    auth: state.auth,
    representative: state.profile.basicInfo || {},
    error: state.profile.error,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewGigStep: (details) => dispatch(setNewGigStep(details)),
    setNewGigSuccess: (details) => dispatch(setNewGigSuccess(details)),
    setNewGigError: (details) => dispatch(setNewGigError(details)),
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step);




